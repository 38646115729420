import React, {useEffect, useRef} from "react";
import {ProductInterface} from "../../interfaces/product.interface";
import {useTranslation} from "react-i18next";
import {addItemToCart} from "../../store/actions/cart.action";
import {ICartItem} from "../../interfaces/cart-item.interface";
import Select from "../utils/Select";
import {OptionInterface, OptionQuestionType} from "../../interfaces/option.interface";
import Modal from "../utils/Modal";
import {CarouselProvider, DotGroup, Slide, Slider} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import {translationHelper} from "../../helpers/translation.helper";
import TextInput from "../utils/TextInput";
import Layout from "../layout.component";
import {RouteComponentProps} from "@reach/router";
import {GatsbySeo, ProductJsonLd} from "gatsby-plugin-next-seo";
import NoSSR from "../utils/no-ssr.component";
import {useConfig} from "../../hooks/use-config.hook";
import {Link} from "gatsby";
import {classNames} from "@eventiofi/eventio-commons-ui";
import {LoadingSpinnerIcon} from "../icons/loading-spinner.icon";
import {TranslationKeys} from "../../interfaces/translation-keys.enum";
import {useAppDispatch, useAppSelector} from "../../hooks/use-redux.hook";
import ProductPrice from "./product.price";
import ProductAmountOptions from "./product-dropdown.options";

interface ProductPageProps extends RouteComponentProps {
    pageContext: {
        id: string;
        product: ProductInterface;
    };
}

const ProductPage: React.FC<ProductPageProps> = (props) => {
    let {pageContext} = props;

    const imageModal = useRef<any>();

    const openModal = (slide: number) => {
        setCurrentSliderSlide(slide);
        imageModal.current.handleOpen();
    };

    const config = useConfig();

    const {cart} = useAppSelector(state => state);

    const {t, i18n} = useTranslation();
    const [defaultSet, setDefaultSet] = React.useState(false);
    const [amount, setAmount] = React.useState<number>(
        pageContext.product.availability.min || 1,
    );
    const [remarks, setRemarks] = React.useState<string[]>([]);
    const [isAdded, setAdded] = React.useState(false);
    const [currentSliderSlide, setCurrentSliderSlide] = React.useState(0);
    const [product, setProduct] = React.useState<ProductInterface>(
        pageContext.product,
    );

    const dispatch = useAppDispatch();

    // Haetaan mahdollisesti päivitetyt tuotetiedot reduxista ja päivitetään ne
    const productState = useAppSelector(state =>
        state.product.products.find(
            (product) => product.id === pageContext.product.id,
        ),
    );

    // Peruutetaan added true, jos ostoskorissa on virhe
    React.useEffect(() => {
        setAdded(false);
    }, [cart.cart_error]);

    useEffect(() => {
        if (productState) {
            setProduct(productState);
        }
    }, [productState]);

    useEffect(() => {
        if (product) {
            const cartItem = cart.items.find(
                (item: ICartItem) => item.product?.id === product.id,
            );
            if (cartItem) {
                if (!product.options) {
                    setAmount(cartItem.amount);
                    return;
                }
            }
            if (defaultSet) return;
            product.options &&
            product.options.forEach((option, i) => {
                let remarksArray = remarks as any;
                const firstOption = option.options[0] as any;
                remarksArray[i] = translationHelper(
                    firstOption?.title,
                    i18n.language,
                );
                setRemarks(remarksArray);
            });
            setDefaultSet(true);
        }
    }, [cart.items, product, i18n.language, remarks, defaultSet]);

    React.useEffect(() => {
        if (window.fbq) {
            window.fbq('track', 'ViewContent', {
                content_name: translationHelper(product.title, config.default_language),
                content_category: 'Products',
                content_ids: [product.id],
                content_type: 'product',
                value: product.price,
                currency: 'EUR'
            });
        }
    }, [])

    const amounts: any[] = ProductAmountOptions(product);

    let description = translationHelper(
        product.long_description,
        i18n.language,
    );

    let short_description = translationHelper(
        product.short_info,
        i18n.language,
    );

    if (!description) description = short_description;

    const updateRemarks = (data: any, index: number) => {
        let remarksArray = remarks as any;
        remarksArray[index] = data;
        setRemarks([...remarksArray]);
    };

    const renderDots = (dotProps: any) => {
        const {carouselStore, totalSlides, currentSlide} = dotProps;

        const handleOnClick = (newSlide: number) => {
            carouselStore.setStoreState({
                currentSlide: newSlide,
            });
        };

        const dots = [];

        for (let i = 0; i < totalSlides; i++) {
            dots.push(
                <button
                    aria-label="slide dot"
                    className={
                        "rounded-full mr-1 p-2 w-2 h-2 hover:bg-gray-500 duration-300 " +
                        (currentSlide === i ? "bg-gray-500" : "bg-gray-300")
                    }
                    type="button"
                    key={i}
                    onClick={() => handleOnClick(i)}
                >
                    &nbsp;
                </button>,
            );
        }
        return dots;
    };

    const handleSubmit = () => {
        dispatch(
            addItemToCart({
                id:
                    product.id +
                    (remarks.length > 0 ? ":" + remarks.join("") : ""),
                price: product.price,
                amount: Number(amount),
                product,
                customer_remarks:
                    remarks.length > 0 ? remarks.join(", ") : undefined,
            }),
        );

        setTimeout(() => {
            setAdded(true);
        }, 500);
    };

    const OptionBuilder = (option: OptionInterface, index: number) => {
        let result = null;
        if (option.question_type === OptionQuestionType.CHOICE) {
            result = (
                <div
                    className="mb-4"
                    key={translationHelper(option.title, i18n.language)}
                >
                    <Select
                        onChange={(e: any) =>
                            updateRemarks(e.target.value, index)
                        }
                        label={translationHelper(option.title, i18n.language)}
                        required={option.required}
                        value={remarks[index]}
                    >
                        {option.options.map((option, i) => (
                            <option
                                value={translationHelper(
                                    option.title,
                                    i18n.language,
                                )}
                                key={
                                    translationHelper(
                                        option.title,
                                        i18n.language,
                                    ) +
                                    "_" +
                                    i
                                }
                            >
                                {translationHelper(option.title, i18n.language)}
                            </option>
                        ))}
                    </Select>
                </div>
            );
        }
        if (option.question_type === OptionQuestionType.TEXT) {
            result = (
                <div
                    className="mb-4"
                    key={translationHelper(option.title, i18n.language)}
                >
                    <TextInput
                        type="text"
                        id={"option_" + index}
                        onChange={(event: any) =>
                            updateRemarks(event.target.value, index)
                        }
                        label={translationHelper(option.title, i18n.language)}
                        value={remarks[index]}
                        required={option.required}
                    />
                </div>
            );
        }
        return result;
    };

    const pageURL = product.slug
        ? `${process.env.GATSBY_WEBSITE_URL}/${product.slug}`
        : `${process.env.GATSBY_WEBSITE_URL}/products/${product.auto_slug}`;

    return (
        <Layout>
            <GatsbySeo
                title={`${translationHelper(
                    product.title,
                    i18n.language,
                )} - ${translationHelper(config.title, i18n.language)}`}
                description={
                    short_description &&
                    short_description.replace(/<[^>]*>?/gm, "")
                }
                canonical={
                    process.env.GATSBY_WEBSITE_URL + "/products/" + product.id
                }
                openGraph={{
                    url: pageURL,
                    title: `${translationHelper(
                        product.title,
                        i18n.language,
                    )} - ${translationHelper(config.title, i18n.language)}`,
                    description:
                        short_description &&
                        short_description.replace(/<[^>]*>?/gm, ""),
                    images:
                        product.images &&
                        product.images.map((image: any) => ({
                            url: image.url,
                        })),
                    type: "og:product",
                }}
                metaTags={[
                    {
                        property: "product:condition",
                        content: "new",
                    },
                    {
                        property: "product:availability",
                        content: product.availability.available
                            ? "in stock"
                            : "out of stock",
                    },
                    {
                        property: "product:price:amount",
                        content: product.price + "",
                    },
                    {
                        property: "product:price:currency",
                        content: "EUR",
                    },
                ]}
                twitter={{
                    handle: "@handle",
                    site: "@site",
                    cardType: "summary_large_image",
                }}
            />
            <ProductJsonLd
                productName={translationHelper(product.title, i18n.language)}
                images={product.images?.map((image: any) => image.url)}
                description={description?.replace(/<[^>]*>?/gm, "")}
                sku={product.own_product_id}
                offers={{
                    url: pageURL,
                    priceCurrency: "EUR",
                    price: product.price + "",
                    itemCondition: "NewCondition",
                    availability: product.availability.available
                        ? "InStock"
                        : "SoldOut",
                }}
            />
            <div className="w-full sm:rounded-md bg-white overflow-hidden sm:shadow py-2">
                <Modal ref={imageModal}>
                    <img
                        src={
                            product.images &&
                            product.images[currentSliderSlide].url
                        }
                        className="max-h-screen py-16 mx-auto"
                        alt=""
                    />
                </Modal>
                <div className="flex flex-wrap">
                    {product.images && (
                        <div className="w-full lg:w-1/2 flex flex-wrap justify-center">
                            <CarouselProvider
                                naturalSlideWidth={100}
                                naturalSlideHeight={100}
                                totalSlides={product.images.length}
                                className="w-full"
                                isPlaying={true}
                                infinite={true}
                            >
                                <Slider>
                                    {product.images.map((image, index) => (
                                        <Slide index={index} key={index}>
                                            <img
                                                src={image.url}
                                                className="cursor-pointer max-h-full mx-auto p-3"
                                                alt=""
                                                onClick={() => openModal(index)}
                                            />
                                        </Slide>
                                    ))}
                                </Slider>
                                {product.images.length > 1 && (
                                    <DotGroup
                                        dotNumbers={true}
                                        className="flex justify-center"
                                        renderDots={(dotProps: any) =>
                                            renderDots(dotProps)
                                        }
                                    />
                                )}
                            </CarouselProvider>
                            <p className="text-sm text-gray-700 mt-4">
                                Klikkaa kuvaa suurentaaksesi sitä
                            </p>
                        </div>
                    )}
                    <div className="w-full lg:w-1/2 justify-center">
                        <div className="px-6 py-4">
                            <div className="flex w-full justify-between">
                                <div className="font-bold mb-6 prose">
                                    <h1>
                                        {translationHelper(
                                            product.title,
                                            i18n.language,
                                        )}
                                    </h1>
                                </div>
                                <div className="font-bold mb-2 text-primary-600">
                                    <ProductPrice product={product}/>
                                </div>
                            </div>

                            <NoSSR>
                                <form
                                    className="mb-6"
                                    onSubmit={(event) => {
                                        event.preventDefault();
                                        handleSubmit();
                                    }}
                                >
                                    {product?.availability.available ? (
                                        <>
                                            <div className="mb-4">
                                                <Select
                                                    onChange={(e) => {
                                                        setAmount(
                                                            Number(
                                                                e.target.value,
                                                            ),
                                                        );
                                                        setAdded(false);
                                                    }}
                                                    label={t(TranslationKeys.COMMON_SELECT_AMOUNT)}
                                                    value={amount}
                                                    id="select-product-amount"
                                                >
                                                    {amounts}
                                                </Select>
                                            </div>
                                            {product.options?.map(
                                                (
                                                    option: OptionInterface,
                                                    index,
                                                ) =>
                                                    OptionBuilder(option, index),
                                            )}
                                            {product.availability.available &&
                                            isAdded && !cart.cart_error ? (
                                                <div>
                                                    <Link
                                                        className="btn btn-success w-full inline-block text-center"
                                                        to="/cart"
                                                    >
                                                        {t(TranslationKeys.COMMON_PROCEED_TO_CART)}
                                                    </Link>
                                                    <Link
                                                        className="btn btn-secondary w-full inline-block text-center mt-2"
                                                        to="/"
                                                    >
                                                        {t(TranslationKeys.COMMON_BACK_TO_SHOPPING)}
                                                    </Link>
                                                </div>
                                            ) : (
                                                <button
                                                    className={classNames(
                                                        isAdded && !cart.cart_error && !cart.isLoadingCartItem ? "btn-success" : "btn-primary",
                                                        "btn w-full inline-block btn-icon btn-icon-left",
                                                    )}
                                                    disabled={amount === 0 || isAdded}
                                                    type="submit"
                                                >
                                                    {cart.isLoadingCartItem ? (
                                                        <>
                                                            <LoadingSpinnerIcon
                                                                className="animate-spin-slow text-white"
                                                            />
                                                            <span>{t(TranslationKeys.COMMON_ADDING_TO_CART)}</span>
                                                        </>
                                                    ) : amount === 0 ? (
                                                        <p>{t(TranslationKeys.COMMON_SELECT_ITEM_AMOUNT_TO_CONTINUE)}</p>
                                                    ) : (
                                                        <p>{t(TranslationKeys.COMMON_ADD_TO_CART)}</p>
                                                    )}
                                                </button>
                                            )}
                                        </>
                                    ) : product.external_buy_button ? (
                                        <a
                                            className="btn btn-primary block text-center mt-2"
                                            type="button"
                                            href={
                                                product.external_buy_button.url
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {translationHelper(
                                                product.external_buy_button
                                                    .label,
                                                i18n.language,
                                            )}
                                        </a>
                                    ) : (
                                        <p>
                                            {translationHelper(
                                                product.availability.info,
                                                i18n.language,
                                            )}
                                            {!product.availability.info && (
                                                /* Koska API palauttaa tällä hetkellä tyhjää, niin kerrotaan nyt edes jotain */
                                                <>{t(TranslationKeys.COMMON_NOT_AVAILABLE)}</>
                                            )}
                                        </p>
                                    )}
                                </form>
                            </NoSSR>

                            <div
                                className="text-gray-800 text-sm mb-4 prose max-w-full"
                                dangerouslySetInnerHTML={{
                                    __html: description || "",
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ProductPage;
